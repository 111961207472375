<template>
  <div
      class="data-table-container"
      :class="{loading:getLoading && !initialFetchComplete}">
    <SearchAndFilters
        v-if="searchesAndFilters"
        :searches-and-filters="searchesAndFilters" />

    <div class="table-tool-bar top">
      <template>
        <div class="tools-left flex">
          <DataTableCustomiser
              v-model="customisedColumns"
              :table-name="name"
              :store-custom-columns="storeCustomColumns"
              :handle-customised-columns="handleCustomisedColumns"
              :reset-custom-columns="resetCustomColumns"
              :show-actions-as-dropdown="showActionsMenuAsDropdown"
              @toggleActionsAsDropdown="setActionsMenuToggle" />
          <core-button
              v-if="polling"
              hint-text="Use this to enable/disable this table from updating every 30 seconds."
              class="ghost-element-loading"
              size="small"
              @click="pollingEnabled = !pollingEnabled">
            <i
                class="el-icon-refresh sync-icon"
                :class="{rotate:pollingEnabled}" />
            {{ pollingButtonLabel }}
          </core-button>
        </div>

        <div class="tools-right">
          <el-pagination
              v-if="paginated && totalDataCount > pagination.per_page"
              class="ghost-element-loading"
              :current-page="pagination.page"
              :page-sizes="pageSizes"
              :page-size="pagination.per_page"
              :layout="paginationLayout"
              :total="totalDataCount || renderedData.length"
              :pager-count="pagerCount"
              @size-change="onPageSizeChange"
              @current-change="onPageChange" />
        </div>
      </template>
    </div>

    <div
        class="banner top"
        :class="{visible:multipleSelection.length}">
      <CoreMultiSelectionBanner
          show-arrow
          :item-label="model.entity|| 'item'"
          :multiple-selection="multipleSelection"
          :bulk-actions="bulkActionsList" />
    </div>

    <GhostLoader
        v-if="getLoading && !initialFetchComplete"
        :columns="columns" />

    <el-table
        v-else
        v-loading="getLoading"
        cell-class-name="truncate el-tooltip"
        :data="renderedData"
        height="100%"
        style="width: 100%"
        stripe
        border
        fit
        :row-class-name="tableRowClassName"
        v-on="$listeners"
        @selection-change="onSelectionChange"

        @header-dragend="onHeaderDragEnd">
      <template slot="empty">
        {{ emptyText }}
        <div v-if="pagination.page > 1">
          <ElButton
              size="small"
              @click.prevent="onPageChange(1)">
            Go to Page 1
          </ElButton>
        </div>
      </template>

      <el-table-column
          v-if="bulkActionsList.length && showBulkActionsColumn"
          type="selection"
          width="55" />

      <el-table-column
          v-if="expandableComponent"
          type="expand">
        <template slot-scope="props">
          <component
              :is="expandableComponent"
              :row="props.row"
              v-on="$listeners" />
        </template>
      </el-table-column>

      <data-table-column
          v-for="(column, index) in customisedColumns"
          :key="index"
          class="column-A"
          :column-key="index"
          :column="column"
          :columns="customisedColumns"
          show-overflow-tooltip
          v-on="$listeners" />

      <template v-if="columnSlots">
        <component
            :is="column"
            v-for="(column, index) in columnSlots"
            :key="`column-${index}`"
            class="truncate el-tooltip column-B"
            v-on="$listeners" />
      </template>

      <ActionsColumn
          v-if="usePropActions && showActionsColumn"
          :actions="actions"
          v-on="$listeners" />


      <el-table-column
          v-if="useModelActions && showActionsColumn"
          fixed="right"
          label="Actions"
          class="actions-column"
          :width="actionsColumnWidth">
        <template v-slot="{row}">
          <core-action-dropdown
              :actions="row.actions"
              :row-id="row.id"
              :show-as-dropdown="showActionsMenuAsDropdown" />
        </template>
      </el-table-column>
    </el-table>

    <div class="table-tool-bar">
      <el-pagination
          v-if="paginated"
          class="ghost-element-loading"
          :current-page="pagination.page"
          :page-sizes="pageSizes"
          :page-size="pagination.per_page"
          :pager-count="pagerCount"
          :layout="paginationLayout"
          :total="totalDataCount || renderedData.length"
          @size-change="onPageSizeChange"
          @current-change="onPageChange" />
    </div>
  </div>
</template>

<script>
import get from "lodash/get"
import debounce from "lodash/debounce"
import isEqual from "lodash/isEqual"
import isNaN from "lodash/isNaN"
import isString from "lodash/isString"
import isNumber from "lodash/isNumber"
import toNumber from "lodash/toNumber"
import arraySort from "array-sort"
import DataTableColumn from "./DataTableColumn"
import DataTableCustomiser from "./DataTableCustomiser"
import ActionsColumn from "./ActionsColumn"
import GhostLoader from "./GhostLoader"
import SearchAndFilters from "./SearchAndFilters"

export default {
  name: "CoreDataTable",
  components: {
    DataTableColumn,
    DataTableCustomiser,
    ActionsColumn,
    GhostLoader,
    SearchAndFilters
  },
  props: {
    tableRowClassName: { default: null, type: [String, Function] },
    tableName: { default: "core-table", type: String },
    loading: { default: false, type: Boolean },
    paginated: { default: true, type: Boolean },
    model: { default: null, type: Function },
    searchesAndFilters: { default: null, type: [Array, Object] },
    bulkActions: { default: null, type: Array },
    modelRequestConfig: { default: () => ({}), type: Object },
    scopes: { default: () => ({}), type: Object },
    expandableComponent: { default: null, type: String },
    height: { default: "100%", type: String },
    columnSlots: { default: () => [], type: Array },
    polling: { default: 0, type: Number },
    emptyText: { default: "No data available", type: String },
    showActionsColumn: { default: true, type: Boolean },
    showBulkActionsColumn: { default: true, type: Boolean },
    columns: {
      type: Array,
      default: () => []
    },
    actions: { type: Array, default: () => [] }
  },

  data() {
    return {
      modelIds: [],
      pagerCount: 5,
      paginationLayout: "total, sizes, prev, pager, next, jumper",
      pageSizes: [10, 25, 50],
      totalDataCount: null,
      customisedColumns: [],
      pollingReference: null,
      isLoading: true,
      initialFetchComplete: false,
      changingPagination: false,
      pollingEnabled: !!this.polling,
      multipleSelection: [],
      showActionsMenuAsDropdown: false
    }
  },
  computed: {
    actionsColumnWidth() {
      if (this.showActionsMenuAsDropdown) {
        return 75
      } else {
        return 160
      }
    },
    bulkActionsList() {
      return this.bulkActions || get(new this.model(), "BulkActions.list", [])
    },
    pollingButtonLabel() {
      return this.pollingEnabled ? "Pause Syncing" : "Resume Syncing"
    },
    itemsSelectedText() {
      const itemsSelected = this.multipleSelection.length

      return `${itemsSelected} ${itemsSelected > 1 ? "items" : "item"} selected`
    },
    useModelActions() {
      const { model, actions, columnSlots } = this
      // @todo: Remove 'hasOptionsColumn' once all option components are removed.
      const hasOptionsColumn = columnSlots.find(slot => {
        const slotString = isString(slot) ? slot : slot.name

        return slotString.match(/options/i)
      })

      return !actions.length && get(new model(), "actions.length") && !hasOptionsColumn
    },
    usePropActions() {
      return this.actions.length
    },
    renderedData() {
      return this.model.findIn(this.modelIds)
    },
    name() {
      return `core-datatable-${this.tableName}`
    },
    scope() {
      const { modelRequestConfig = {} } = this

      return modelRequestConfig.scope
    },
    getLoading() {
      return this.isLoading
    },
    hasNoFiltersOrSearches() {
      // TODO: derive logic from url
      const hasFiltersOrSearches = Object.keys(get(this, "strippedRouteQuery", {})).length

      return !hasFiltersOrSearches
    },
    shouldUpdate() {
      return this.model.all().length
    },
    routeQuery() {
      return this.$route.query
    },
    pagination() {
      return { per_page: toNumber(this.routeQuery.per_page), page: toNumber(this.routeQuery.page) }
    },
    strippedRouteQuery() {
      // eslint-disable-next-line
      const { page, per_page, d, ...rest } = this.routeQuery

      // @TEMP_HACK: passing in an object of "scopes" so that the data-table doesn't stop polling because it is retrieved form the url query
      Object.keys(this.scopes).forEach(key => {
        delete rest[key]
      })

      return rest || {}
    }
  },
  watch: {
    strippedRouteQuery: {
      deep: true,
      handler(newValue, oldValue) {
        this.$nextTick(() => {
          if (!isEqual(newValue, oldValue) && this.initialFetchComplete) {
            this.debouncedGetData()
          }
        })
      }
    },
    hasNoFiltersOrSearches: {
      immediate: true,
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.hasNoFiltersOrSearches ? (this.pollingEnabled = true) : (this.pollingEnabled = false)
        })
      }
    },
    multipleSelection: {
      handler() {
        this.multipleSelection.length ? (this.pollingEnabled = false) : (this.pollingEnabled = true)
      }
    },
    scope: {
      deep: true,
      handler() {
        this.getData()
      }
    },
    pagination: {
      immediate: true,
      handler(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.changingPagination = true
        }

        if (isNaN(this.pagination.per_page)) {
          this.onPageSizeChange(25)
        } else if (isNaN(this.pagination.page)) {
          this.onPageChange(1)
        } else if (!isEqual(newValue, oldValue)) {
          this.getData()
        }
      }
    },
    shouldUpdate: {
      immediate: true,
      handler() {
        // @HACK: this worked in local to "wake up" this watcher... Maybe it'll work in staging? :shrug:
        if (this.initialFetchComplete && !this.changingPagination) {
          this.getData()
        }

        this.changingPagination = false
      }
    }
  },
  async mounted() {
    this.debouncedGetData = debounce(this.getData, 500)
    this.handleCustomisedColumns()
    this.checkActionsMenuToggle()
    this.startPolling()
  },
  beforeDestroy() {
    clearInterval(this.pollingReference)

    this.pollingReference = null
  },
  methods: {
    onSelectionChange(val) {
      this.multipleSelection = val
    },
    async getData(fromPolling) {
      const { model, modelRequestConfig } = this

      if (!model) return null

      if (!fromPolling) this.isLoading = true

      const { response } = await model
        .api()
        .fetchAll({ _mergeUrlParams: true, ...modelRequestConfig })

      const meta = get(response, "data.data.meta", {})

      if (isNumber(parseInt(meta.total)) || isNumber(meta.total_count)) {
        this.totalDataCount = parseInt(meta.total) || meta.total_count
      }

      this.modelIds = response.data.data.data.map(({ id }) => id)

      this.initialFetchComplete = true

      if (!fromPolling) this.isLoading = false
    },
    storeCustomColumns(customColumns) {
      const sortedColumns = customColumns.map((col, index) => {
        if (col.order === undefined || col.order !== index) {
          col.order = index
        }
        return col
      })

      localStorage.setItem(this.name, JSON.stringify(sortedColumns))
    },
    resetCustomColumns() {
      localStorage.setItem(this.name, JSON.stringify(this.columns))
      this.handleCustomisedColumns()
    },
    toggleActionsAsDropdown(val) {
      this.showAsDropdown = val
    },
    setActionsMenuToggle(val) {
      localStorage.setItem("showTableActionsAsDropdown", val)
      this.showActionsMenuAsDropdown = val
    },
    checkActionsMenuToggle() {
      if (JSON.parse(localStorage.getItem("showTableActionsAsDropdown"))) {
        this.showActionsMenuAsDropdown = JSON.parse(localStorage.getItem("showTableActionsAsDropdown"))
      }
      return true
    },
    handleCustomisedColumns() {
      const storedColumns = JSON.parse(localStorage.getItem(this.name)) || []
      // removes any non-existing columns
      const strippedColumns = storedColumns.filter(col => {
        const colExists = this.columns.find(c => c.label === col.label)

        return colExists
      })

      // Ensures we only extract the settings we want from the custom col
      const extractCustomSettings = col => {
        let defaultWidth = undefined

        if (col.prop === "id") {
          defaultWidth = 65
        }

        const { sortable, visible = true, order, width = defaultWidth } = col

        const customSettings = { sortable, visible, order }

        if (width) {
          customSettings.width = width
        }

        return customSettings
      }

      // merges custom Column Settings onto columns
      const customisedColumns = arraySort(
        this.columns.map(col => {
          const customCol = strippedColumns.find(c => c.prop === col.prop) || {}

          return { ...col, ...extractCustomSettings(customCol) }
        }),
        "order"
      )

      // stores adjusted columns back into store
      this.storeCustomColumns(customisedColumns)

      this.customisedColumns = customisedColumns
    },
    startPolling() {
      const { polling, pollingReference, getData } = this
      const self = this

      if (polling && !pollingReference) {
        this.pollingReference = setInterval(() => {
          const { initialFetchComplete, pollingEnabled } = self

          const shouldFetchFromPolling = initialFetchComplete && pollingEnabled

          if (shouldFetchFromPolling) {
            getData(true)
          }
        }, polling)
      }
    },
    onPageSizeChange(newPageSize) {
      const { routeQuery } = this

      let nextQuery = { ...JSON.parse(JSON.stringify(routeQuery)) }

      nextQuery.per_page = newPageSize

      this.$router.push({ path: this.$route.path, query: nextQuery })
    },
    onPageChange(nextPage) {
      const { routeQuery } = this

      let nextQuery = { ...JSON.parse(JSON.stringify(routeQuery)) }

      nextQuery.page = nextPage

      this.$router.push({ path: this.$route.path, query: nextQuery })
    },
    onHeaderDragEnd(newWidth, oldWidth, column) {
      const { property } = column
      const { customisedColumns } = this

      this.customisedColumns = customisedColumns.map(col => {
        if (col.prop === property) {
          col.width = newWidth
        }

        return col
      })

      this.storeCustomColumns(this.customisedColumns)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/Modules/Core/assets/css/index.scss";

.rotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

::v-deep  .el-table td {
  border-bottom: none;
}

::v-deep  .truncate .cell {
  @extend .truncate;
}

.data-table-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

::v-deep  .data-table-container {
  display: flex;
  background: red;

  & .el-table {
    @extend .flex-auto;
    border-top: 1px solid lightgrey;

    th.is-leaf {
      vertical-align: top;
    }
  }
}

::v-deep .el-table-column--selection {
  .cell {
    display: flex;
    justify-content: center;
  }
}

::v-deep  .el-table__body-wrapper {
  height: 100% !important;
}

::v-deep  .el-table {
  td.actions-column {
    padding: 0 5px !important;
    .cell {
      padding: 0 !important;
    }
  }
}

::v-deep  .caret-wrapper {
  position: absolute !important;
  right: 4px;
  top: -5px;
  background: white;
}

.banner {
  transition: all 0.2s;
  height: 0;

  &.visible {
    height: 32px;
  }
}

.table-tool-bar {
  width: 100%;
  padding: var(--margin-s) 0;
  display: flex;
  justify-content: flex-end;

  .tools-right {
    margin-left: auto;
  }

  .sync-icon {
    margin-right: var(--margin-s);
    color: red;

    &.rotate {
      color: rgb(28, 184, 28);
    }
  }

  .tools-left {
    margin-right: auto;
    > * {
      margin-right: var(--margin-m);
    }
  }

  &.top {
    border-bottom: 1px solid #ebeef5;
    border-top: 1px solid #ebeef5;
  }
}

.header-spacer {
  height: 32px;
}

::v-deep  .el-table .error-row {
  background: #ffc0cb69;
}

::v-deep  .el-table .warning-row {
  background: oldlace;
}

::v-deep  .el-table .success-row {
  background: #f0f9eb;
}

::v-deep  .el-table--striped .el-table__body tr.el-table__row--striped td {
  background-color: rgba(0, 0, 0, 0.02);
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
  .table-tool-bar {
    flex-wrap: wrap;
  }
  ::v-deep  .el-pagination {
    display: flex;
    flex-wrap: wrap;

    .el-pagination__sizes,
    .el-pagination__jump {
      display: none;
    }
  }
}
</style>
